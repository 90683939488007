<template>
  <v-chip-group color="default" active-class="">
    <v-chip small @click="handleClickFilters()">
      <v-icon left small>mdi-filter-multiple</v-icon> {{ __("filtros") }}
    </v-chip>
    <v-chip small @click="handleClickClear()" v-if="filters.length">
      <v-icon left small>mdi-filter-off</v-icon> {{ __("limpar") }}
    </v-chip>
    <v-chip
      small
      v-for="filter in filters"
      :key="filter.column.id"
      :title="getFilterValueString(filter)"
      @click="handleClickItem(filter)"
    >
      {{ filter.column.label }}: {{ getFilterValueString(filter, 2) }}
    </v-chip>
    <template v-if="!filters.length">
      <v-chip
        small
        v-for="filter in storedFilters"
        :key="filter.id"
        :title="filter.name"
        @click="handleApplyFilter(filter.advanced_filters)"
      >
        <v-icon left small>mdi-lightning-bolt</v-icon> {{ filter.name }}
      </v-chip>
    </template>
  </v-chip-group>
</template>

<script>
import { getFilterValueString } from "@/tools/advanced_filters";
import i18n from "@/mixins/i18n";

export default {
  props: {
    filters: Array,
    storedFilters: Array,
  },
  mixins: [i18n],

  data() {
    return {
      i18n_prefix: "advanced_filters",
      getFilterValueString,
    };
  },

  methods: {
    handleClickClose(filter) {
      this.$emit("removeFilter", filter);
    },

    handleApplyFilter(filter) {
      this.$emit("applyFilter", filter);
    },

    handleClickFilters() {
      this.$emit("click:filter");
    },

    handleClickItem(filter) {
      this.$emit("click:item", filter);
    },

    handleClickClear() {
      this.$emit("click:clear");
    },
  },
};
</script>
