<template>
  <v-card>
    <v-app-bar color="white" flat>
      <v-toolbar-title class="font-weight-medium">{{
        $capitalize($tc("model.insureds_insureds_title"))
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <ScopeProvider scope="leads.participants.add">
        <v-btn color="primary" text @click="handleClickNewParticipant">
          <v-icon left>mdi-plus</v-icon>
          {{ __("Adicionar") }}
        </v-btn>
      </ScopeProvider>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-divider></v-divider>

    <v-data-table
      :headers="headers"
      :items="lead.participants"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:header.name>
        {{ __("Nome") }}
      </template>

      <template v-slot:header.type.description>
        {{ __("Tipo") }}
      </template>

      <template v-slot:header.contact1>
        {{ __("Contato") }}
      </template>

      <template v-slot:header.actions>
        {{ __("Ações") }}
      </template>

      <template v-slot:item.name="{ item }">
        {{ item.contact.name }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.type }}
      </template>
      <template v-slot:item.contact1="{ item }">
        {{ item.contact.contact1 }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <ScopeProvider scope="leads.participants.edit">
              <v-list-item @click="handleClickEditParticipant(item)">
                <v-icon left size="20">mdi-pencil</v-icon>
                <v-list-item-title>{{ __("Editar") }}</v-list-item-title>
              </v-list-item>
            </ScopeProvider>
            <ScopeProvider scope="leads.participants.delete">
              <v-list-item
                value="true"
                @click.stop="confirmDelete(item.id)"
                v-if="item.type_id != 1"
              >
                <v-icon color="red" left size="20">mdi-delete</v-icon>
                <v-list-item-title class="red--text">{{
                  __("Remover")
                }}</v-list-item-title>
              </v-list-item>
            </ScopeProvider>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="new_participant_dialog" max-width="600px" persistent>
      <FormAddParticipant
        v-model="participant_form"
        @close="new_participant_dialog = false"
        @create="createParticipant"
        @update="updateParticipant"
      />
    </v-dialog>

    <confirm-action-dialog
      :confirm_action="confirm_deletion"
      @change="confirm_deletion = $event"
      @action="removeParticipant(delete_id)"
      :message="__('O participante será permanentemente excluído')"
    ></confirm-action-dialog>
  </v-card>
</template>

<script>
import FormAddParticipant from "./FormAddParticipant";
import ConfirmActionDialog from "../ConfirmActionDialog";
import ScopeProvider from "../ScopeProvider";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  name: "participants-detail",
  components: { FormAddParticipant, ConfirmActionDialog, ScopeProvider },
  data: () => ({
    i18n_prefix: "participants",
    new_participant_dialog: false,
    removing_participant: null,
    participant_form: null,
    confirm_deletion: false,
    delete_id: null,
    headers: [
      { text: "Nome", value: "name", sortable: false },
      {
        text: "Tipo",
        value: "type.description",
        sortable: false,
      },
      {
        text: "Contato",
        value: "contact1",
        sortable: false,
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  methods: {
    createParticipant(payload) {
      this.participant_form = null;
      let participants = this.lead.participants;
      participants.push(payload);

      this.$store.commit("setLead", {
        ...this.lead,
        participants,
      });
    },
    updateParticipant(payload) {
      this.participant_form = null;
      this.$store.commit("setLead", {
        ...this.lead,
        scheduling_participant:
          payload.id == this.lead.scheduling_participant_id ? payload : null,
        participants: this.lead.participants.map((participant) => {
          return participant.id === payload.id ? payload : participant;
        }),
      });
    },
    handleClickNewParticipant() {
      this.participant_form = null;
      this.new_participant_dialog = true;
    },
    handleClickEditParticipant(participant) {
      this.participant_form = { ...participant };
      this.new_participant_dialog = true;
    },
    isParticipantInsured(participant_id) {
      return participant_id === this.lead.participant_insured.id;
    },
    getAvatarColor(participant_id) {
      return this.isParticipantInsured(participant_id)
        ? "primary lighten-1"
        : "grey lighten-1";
    },
    submitParticipant(payload) {
      let participants = this.lead.participants;

      if (participants.find((participant) => participant.id === payload.id)) {
        participants = participants.map((participant) => {
          return participant.id === payload.id ? { ...payload } : participant;
        });
      } else {
        participants.push(payload);
      }

      this.$store.commit("setLead", {
        ...this.lead,
        participants,
      });

      this.new_participant_dialog = false;
    },
    confirmDelete(id) {
      this.delete_id = id;
      this.confirm_deletion = true;
    },
    async removeParticipant(id) {
      this.removing_participant = id;
      try {
        await this.$http.delete("/participants/" + id);

        this.$store.commit("setLead", {
          ...this.lead,
          participants: this.lead.participants.filter(
            (participant) => participant.id !== id
          ),
        });

        this.$store.commit("sendMessage", {
          text: this.__("Participante removido", "participants"),
        });

        this.confirm_deletion = false;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: error.response.data.message,
          color: "red",
        });
      } finally {
        this.removing_participant = null;
      }
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
  },
};
</script>
