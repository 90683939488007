<template>
  <div class="mb-15">
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title>
              {{ $capitalize($tc("model.products_products_title")) }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="getProducts()" icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn @click="handleClickNewProduct()" color="primary"
              ><v-icon left>mdi-plus</v-icon
              >{{
                $capitalize($tc("model.products_new_product_button"))
              }}</v-btn
            >
          </v-toolbar>
          <v-card-text v-if="loading">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-card-text>
          <v-card-text v-else-if="products.length == 0">
            <div class="text-center my-5">
              <v-img src="@/assets/box.svg" width="100" class="mx-auto"></v-img>
              <div class="title">
                {{ $capitalize($tc("model.products_no_products")) }}
              </div>
              <div class="mt-3">
                <v-btn
                  @click="handleClickNewProduct()"
                  color="primary"
                  outlined
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $capitalize($tc("model.products_register_button")) }}
                </v-btn>
              </div>
            </div>
          </v-card-text>
          <div v-else>
            <v-list>
              <div v-for="product in products" :key="product.id">
                <v-divider></v-divider>
                <v-list-item :to="`products/${product.id}`">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ product.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ product.owner.contact.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mt-2">
                      <span class="mr-2">
                        <v-icon
                          v-if="product.fitting === true"
                          small
                          color="green"
                          :title="$capitalize($tc('model.products_framed'))"
                          >mdi-checkbox-marked</v-icon
                        >
                        <v-icon
                          v-else-if="product.fitting === false"
                          small
                          color="red"
                          :title="$capitalize($tc('model.products_unframed'))"
                          >mdi-close-box</v-icon
                        >
                        <v-icon
                          v-else
                          small
                          :title="$capitalize($tc('model.products_not_rated'))"
                          >mdi-help</v-icon
                        >
                      </span>

                      <span class="mr-2" v-if="product.technical_analysis">
                        <v-icon
                          small
                          :title="
                            $capitalize(
                              $tc('model.products_technical_evaluation')
                            )
                          "
                          >mdi-text</v-icon
                        >
                      </span>

                      <span v-if="product.photos.length" class="mr-2">
                        {{ product.photos.length }}
                        <v-icon
                          small
                          :title="
                            $capitalize($tc('model.products_contains_photo'))
                          "
                          >mdi-image</v-icon
                        >
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn :to="`products/${product.id}`" icon>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { index as indexProducts } from "@/services/products/products";

export default {
  data() {
    return {
      products: [],
      loading: false,
    };
  },

  methods: {
    async getProducts() {
      const leadNumber = this.$route.params.lead_number;
      this.products = [];

      this.loading = true;
      const products = await indexProducts(leadNumber);
      this.products = products;
      this.loading = false;
    },

    handleClickNewProduct() {
      const leadNumber = this.$route.params.lead_number;

      if (
        ["24HCM7000205", "24HCM6000196", "24EPST2427576"].includes(leadNumber)
      ) {
        window.location.replace(
          `https://demo.portalhcm.com/budgets/${leadNumber}`
        );
        return;
      }

      this.$router.push("products/new");
    },
  },

  created() {
    this.getProducts();
  },
};
</script>
