<style lang="scss" scoped>
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
  max-height: 500px;
}
.fade-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-leave-to {
  transition: all 0.1s;
  opacity: 0;
  max-height: -200px;
}
</style>

<template>
  <div>
    <v-card color="white" outlined>
      <div style="max-width: 950px; position: relative" class="mx-auto">
        <v-row>
          <v-col cols="12" sm="4">
            <v-sheet class="mt-2">
              <v-card flat>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ risk_location_city.name || "S/F" }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ formatDate(lead.claim_date) }},
                      {{ sinisterWheather.weather }}
                    </v-list-item-subtitle>
                    <v-row>
                      <v-col cols="4" class="text-h4 font-weight-thin">
                        {{ sinisterWheather.temperature }}&deg;C
                      </v-col>
                      <v-col>
                        <v-icon
                          style="margin-left: -15px"
                          class="mt-1"
                          size="30"
                          >{{ sinisterWheather.icon }}</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-sheet>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card-text>
              <v-sheet class="text-center mb-4">
                <v-menu open-on-hover>
                  <template v-slot:activator="{ on }">
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      style="margin-top: 0px"
                      v-bind="score"
                      v-on="on"
                    >
                      <span v-if="lead.score" class="subtitle-1">{{
                        score.number || 0
                      }}</span>
                      <span v-else><v-icon size="30">mdi-help</v-icon></span>
                    </v-progress-circular>
                  </template>
                  <v-card>
                    <v-card-text class="font-weight-medium pb-0"
                      >Legenda</v-card-text
                    >
                    <v-list>
                      <v-list-item>
                        <v-list-item-icon class="mr-3">
                          <v-icon color="green" left>mdi-circle</v-icon>
                          <span class="green--text">701 - 1000</span>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Nível 3</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon class="mr-3">
                          <v-icon color="orange" left>mdi-circle</v-icon>
                          <span class="orange--text">401 - 700</span>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Nível 2</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon class="mr-3">
                          <v-icon color="red" left>mdi-circle</v-icon>
                          <span class="red--text">0 - 400</span>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Nível 1</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-sheet>
              <div class="mt-1">
                <h1 class="text-center">{{ lead.identifier_code }}</h1>
                <h3 class="text-center">{{ lead.external_reference }}</h3>
                <h3 class="text-center">
                  <span
                    v-if="lead.subtype_id"
                    :title="`${lead.type.description}/${lead.subtype.description}`"
                  >
                    {{ lead.subtype.description }}
                  </span>
                  <span v-else-if="lead.type">
                    {{ lead.type.description }}
                  </span>
                </h3>

                <v-sheet class="text-center mt-2">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" label small>
                        <v-icon left>mdi-domain</v-icon>
                        {{
                          lead.default_service.external_status
                            ? lead.default_service.external_status.state.name
                            : lead.default_service.state
                        }}
                      </v-chip>
                    </template>
                    <span>{{ __("Status externo", "leads") }}</span>
                  </v-tooltip>
                </v-sheet>

                <v-sheet class="text-center mt-2 display-inline">
                  <v-menu
                    v-if="lead.id"
                    top
                    close-on-click
                    max-height="500"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        :loading="loading_status"
                        elevation="0"
                        color="#f1f1f1"
                        :disabled="
                          !$store.getters['auth/getScope'](
                            'leads.update_status'
                          )
                        "
                      >
                        <v-icon
                          size="13"
                          class="mr-2"
                          :color="lead.status.color"
                          >mdi-circle</v-icon
                        >
                        {{ lead.status ? lead.status.name : "Sem status" }}
                        <v-icon right small>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in lead_statuses"
                        :key="index"
                        @click="changeLeadStatus(item)"
                        :input-value="item.id == lead.status.id"
                        color="primary"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-sheet>
                <h3
                  class="text-center font-weight-light py-5"
                  v-if="lead.claim_description"
                >
                  {{ lead.claim_description }}
                </h3>
              </div>
            </v-card-text>
          </v-col>
          <div class="notifications-icons">
            <div class="d-inline" v-if="lead_alerts.length">
              <v-menu v-model="alert_menu">
                <template v-slot:activator="{ on }">
                  <v-badge
                    :content="lead_alerts.length"
                    :value="lead_alerts.length"
                    color="red"
                    overlap
                  >
                    <v-icon v-on="on" color="warning">mdi-alert-circle</v-icon>
                  </v-badge>
                </template>
                <v-card width="500" max-height="500">
                  <v-app-bar flat color="white">
                    <v-toolbar-title>Alertas</v-toolbar-title>
                  </v-app-bar>
                  <v-list>
                    <v-list-item
                      v-for="(alert, index) in lead_alerts"
                      :key="'alert-' + index"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                          <v-icon left>mdi-circle-medium</v-icon>
                          {{ alert.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>

            <div class="ml-4 d-inline">
              <lead-notification-menu v-model="lead_notification_menu" bottom />
            </div>
          </div>
        </v-row>

        <RelatedLeadsAlert :lead="lead" />

        <ScopeProvider scope="leads.approve">
          <v-alert dense type="warning" v-if="lead.id && lead.approved == null">
            {{ __("A lead ainda não foi aprovada", "leads") }}
            <template v-slot:append>
              <v-btn
                @click="confirmRefuseLead"
                color="red"
                small
                class="mr-1"
                >{{ __("Recusar", "leads") }}</v-btn
              >
              <v-btn @click="confirmAcceptLead" color="green" small>{{
                __("Aceitar", "leads")
              }}</v-btn>
            </template>
          </v-alert>
        </ScopeProvider>

        <v-divider></v-divider>

        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-toolbar flat>
          <v-tabs>
            <v-tab
              v-for="tab in primaryTabs"
              :key="tab.name"
              :to="{ name: tab.route }"
              :exact="tab.exact"
            >
              {{ tab.name }}
            </v-tab>
            <v-menu bottom left v-if="otherTabs.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text class="align-self-center" v-bind="attrs" v-on="on">
                  {{ __("Mais", "leads") }}
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item
                  v-for="tab in otherTabs"
                  :key="tab.name"
                  :to="{ name: tab.route }"
                  :exact="tab.exact"
                >
                  {{ tab.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tabs>
          <template v-if="is_lead_from_eps === true">
            <v-spacer />
            <v-btn
              text
              :to="{ name: 'lead_send_service' }"
              class="align-self-center"
            >
              <v-icon left>mdi-cloud-upload-outline</v-icon>
              {{ $capitalize($tc("model.integration_send_button")) }}
            </v-btn>
          </template>
          <template v-if="is_lead_from_eps === false">
            <v-spacer />
            <ScopeProvider scope="leads.edit">
              <v-btn
                text
                :to="{ name: 'lead_edit', params: { lead_number } }"
                class="align-self-center"
                small
              >
                <v-icon left small>mdi-pencil</v-icon>
                {{ $capitalize($tc("model.insureds_edit_insured")) }}
              </v-btn>
            </ScopeProvider>
            <ScopeProvider scope="leads.delete">
              <v-btn
                text
                class="align-self-center"
                color="red"
                @click="confirmDeleteLead"
                small
              >
                <v-icon left small>mdi-delete</v-icon>
                {{ $capitalize($tc("model.integration_remove_button")) }}
              </v-btn>
            </ScopeProvider>
          </template>
        </v-toolbar>
      </div>
    </v-card>

    <v-container style="max-width: 950px">
      <router-view></router-view>
    </v-container>

    <RefuseLeadDialog
      v-model="refuse_dialog"
      :lead="lead"
      @updateLead="updateRefusedLead"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { changeStatus, approveLead, deleteLead } from "../services/lead";
import ScopeProvider from "@/components/ScopeProvider";
import { lead_types } from "@/services/lead_types";
import {
  indexByLeadUnread,
  indexByLeadRead,
} from "@/services/lead_notifications";
import { formatDate } from "@/tools/date";

import RefuseLeadDialog from "@/components/Lead/RefuseLeadDialog";
import LeadNotificationMenu from "@/components/Lead/Notification/LeadNotificationMenu";
import i18n from "@/mixins/i18n";
import RelatedLeadsAlert from "@/components/Lead/Alerts/RelatedLeadsAlert";

export default {
  name: "lead_detail",
  mixins: [i18n],
  components: {
    ScopeProvider,
    RefuseLeadDialog,
    LeadNotificationMenu,
    RelatedLeadsAlert,
  },
  data: function () {
    return {
      poll_notifications: null,
      tab: 0,
      tabs: [
        {
          name: this.__("Resumo", "leads"),
          route: "lead_detail_data",
          scope: "leads.read",
          exact: true,
        },
        {
          name: this.__("apólice", "leads"),
          route: "lead_detail_policy",
          scope: "leads.read",
        },
        {
          name: this.__("Agendamentos", "leads"),
          route: "lead_detail_scheduling_list",
          scope: "leads.schedulings.read",
        },
        {
          name: this.__("Produtos", "leads"),
          route: "ProductsList",
          scope: "leads.read",
          lead_types: [lead_types.riscos_eletricos],
        },
        {
          name: this.__("Orçamento", "leads"),
          route: "budgets",
          scope: "leads.read",
          lead_types: [lead_types.multirisks],
        },
        {
          name: this.__("Relatórios", "leads"),
          route: "reports",
          scope: "leads.reports.read",
        },
        {
          name: this.__("Companhia", "leads"),
          route: "lead_detail_comments",
          scope: "leads.comments.read",
        },
        {
          name: this.$capitalize(
            this.$tc("model.annotation_annotations_title")
          ),
          route: "lead_detail_annotation",
          scope: "leads.annotations.read",
        },
        {
          name: this.__("Gestão documental", "leads"),
          route: "lead_detail_files",
          scope: "leads.attachments.read",
        },
        {
          name: this.__("Integração", "leads"),
          route: "integration",
          scope: "leads.integration.read",
        },
        {
          name: this.__("Formulários", "leads"),
          route: "lead_lead_forms",
          scope: "leads.read",
        },
      ],
      menu: false,
      alert_menu: false,
      show_more_info: false,
      loading: false,
      loading_status: null,
      refuse_dialog: false,

      lead_notification_menu: false,
    };
  },
  methods: {
    confirmDeleteLead() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.__("Excluir lead", "new_lead") + this.lead_number,
        description: this.__(
          "Tem certeza que deseja excluir esta lead?",
          "new_lead"
        ),
        positive_action: () => this.deleteLead(),
      });
    },
    async deleteLead() {
      try {
        await deleteLead(this.lead_number);
        this.$store.commit("sendMessage", {
          color: "success",
          text: this.__("Lead excluída com sucesso", "new_lead"),
        });
        this.$router.replace({ name: "leads_list" });
      } catch (error) {
        if (error.response.status == 422) {
          this.$store.commit("sendMessage", {
            color: "red",
            text: error.response.message,
          });
        }
      }
    },
    getTemperature(id) {
      if (
        ["24HCM7000205", "24HCM6000196", "24EPST2427576"].includes(
          this.lead.identifier_code
        )
      ) {
        return 13;
      }

      const variation = this.lead.id % 7;
      return 19 + variation;
    },
    formatDate(date) {
      return formatDate(date);
    },
    ...mapActions("lead_statuses", ["loadStatuses"]),
    updateStatusHistory(value, options = {}) {
      this.$store.commit("setLead", {
        ...this.lead,
        status: value.status,
        ...options,
      });

      this.$store.commit("setLeadStatusHistory", value.status_history);
    },
    async changeLeadStatus(status) {
      this.loading_status = true;
      try {
        const new_status = await changeStatus(this.lead.id, status.id);
        this.updateStatusHistory(new_status);
      } catch (error) {
        console.error(error);
        alert("error");
      } finally {
        this.loading_status = false;
      }
    },
    async getLead() {
      this.loading = true;
      try {
        const response = await this.$http.get(`/leads/${this.lead_number}`);
        this.$store.commit("setLead", {
          ...response.data,
        });
        await this.loadNotifications();
      } catch (error) {
        this.$router.replace("/leads/ops");
      } finally {
        this.loading = false;
      }
    },
    async confirmAcceptLead() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.__("Tem certeza?", "leads"),
        description: this.__(
          "Ao aceitar a lead, esta ação não poderá ser desfeita",
          "leads"
        ),
        positive_action: () => this.acceptLead(),
      });
    },
    async acceptLead() {
      this.$store.commit("setLoading", { loading: true });
      try {
        const response = await approveLead(this.lead.id, true);

        this.$store.commit("sendMessage", {
          text: this.__("Lead aprovado com sucesso.", "leads"),
        });

        this.updateStatusHistory(response, {
          approved_by: response.approved_by,
          approved: response.approved,
          approve_justification: response.approve_justification,
        });
      } finally {
        this.$store.commit("setLoading", { loading: false });
      }
    },
    confirmRefuseLead() {
      this.refuse_dialog = true;
    },
    updateRefusedLead(lead) {
      this.updateStatusHistory(lead, {
        approved_by: lead.approved_by,
        approved: lead.approved,
        approve_justification: lead.approve_justification,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    routeFromEpsAllowed(route) {
      const tabs_eps_only = ["integration", "lead_send_service"];

      if (this.is_lead_from_eps) {
        return true;
      } else {
        return !tabs_eps_only.includes(route);
      }
    },
    routeForThisLeadTypeAllowed(route) {
      const tab = this.tabs.find((tab) => tab.route == route);
      return tab?.lead_types
        ? tab.lead_types.some((type) => type === this.lead_type)
        : true;
    },
    async loadNotifications() {
      const unreadResponse = await indexByLeadUnread(this.lead_number);
      const unread_notifications = unreadResponse.data;

      this.$store.commit("setLead", {
        ...this.lead,
        unread_notifications,
      });
    },
    load_page(new_lead_number, old_lead_number = null) {
      if (old_lead_number === null && this.lead) {
        old_lead_number = this.lead.identifier_code;
      }

      if (old_lead_number) {
        Echo.leaveChannel(`private-leads.${old_lead_number}`);
      }

      this.$store.commit("clearLead");
      this.getLead();
      this.loadStatuses();

      Echo.private(`leads.${new_lead_number}`).listen(
        "LeadNotificationsChanged",
        (e) => {
          this.$store.commit("setLead", {
            ...this.lead,
            unread_notifications: e.unread_notifications,
          });
        }
      );
    },
    reloadLeadByRouteChanging(to, from) {
      if (!from) {
        return;
      }

      if (to.params.lead_number === from.params.lead_number) {
        return;
      }

      this.load_page(to.params.lead_number, from.params.lead_number);
    },
    changePageTitleByRouteChanging(to) {
      to.meta.title = `${to.params.lead_number} - HCM`;
    },
  },
  computed: {
    all_notifications() {
      return this.unread_notifications.concat(this.read_notifications);
    },
    read_notifications() {
      return this.lead?.read_notifications || [];
    },
    unread_notifications() {
      return this.lead?.unread_notifications || [];
    },
    lead_alerts() {
      const alerts = [];
      if (!this.lead.claim_description)
        alerts.push({
          text: "A reclamação do lesado ainda não foi informada",
        });

      (this.lead.participants || []).forEach((participant) => {
        if (participant.insured && participant.risk_location == null) {
          alerts.push({
            text: `Lesado ${participant.contact.name} não possui localização de vistoria`,
          });
        }
      });

      /**
       * Adiciona alerta caso não existam verbas seguras
       * identificadas de acordo com a tipologia do sinistro.
       */
      if (
        this.lead_type === lead_types.multirisks &&
        !this.lead.policy.building_insurance_object_id
      ) {
        alerts.push({
          text: `Identifique a verba segura de edifício`,
        });
      } else if (
        this.lead_type === lead_types.riscos_eletricos &&
        !this.lead.policy.contents_insurance_object_id
      ) {
        alerts.push({
          text: `Identifique a verba segura de conteúdo`,
        });
      }

      return alerts;
    },
    sinisterWheather: function () {
      const temperature = this.getTemperature();
      let icon = "mdi-cloud";
      let weather = "Mostly Sunny";

      if (temperature <= 20) {
        icon = "mdi-weather-pouring";
        weather = "Rain showers";
      }
      if (temperature >= 21) {
        icon = "mdi-weather-partly-cloudy";
        weather = "Partly cloudy";
      }
      if (temperature >= 25) {
        icon = "mdi-white-balance-sunny";
        weather = "Clear sky";
      }
      if (
        ["24HCM7000205", "24HCM6000196", "24EPST2427576"].includes(
          this.lead.identifier_code
        )
      ) {
        icon = "mdi-weather-partly-cloudy";
        weather = "Partly cloudy";
      }

      return {
        temperature,
        icon,
        weather,
      };
    },
    score: function () {
      const total = 1000;
      const number = this.lead.score || 1000;
      const percentage = (number / total) * 100;
      let color = "red";

      if (number >= 0 && number <= 400) {
        color = "red";
      }
      if (number >= 401 && number <= 700) {
        color = "orange";
      }
      if (number >= 701 && number <= 1000) {
        color = "green";
      }

      if (this.lead.score == null) {
        color = "grey";
      }

      return {
        color,
        number,
        value: percentage,
      };
    },
    ...mapState("lead_statuses", ["lead_statuses"]),
    lead_number() {
      return this.$route.params["lead_number"];
    },
    lead: function () {
      return this.$store.getters.getLead;
    },
    lead_type() {
      return this.lead?.lead_type;
    },
    tree: function () {
      return [
        {
          id: 2,
          name: "Participantes",
          icon: "mdi-account-group",
          children: this.lead.participants.map((participant) => {
            return {
              id: `02${participant.id}`,
              name: participant?.contact?.name,
              icon: "mdi-account",
              children: participant.services.map((service) => ({
                id: `02${participant.id}${service.id}`,
                name: service.service_number,
                icon: "mdi-wrench",
                to: "/leads",
              })),
            };
          }),
        },
        {
          id: 3,
          name: "Serviços",
          icon: "mdi-toolbox",
          children: this.lead.services.map((service) => ({
            id: `03${service.id}`,
            name: service.service_number,
            icon: "mdi-wrench",
          })),
        },
      ];
    },
    allowedTabs: function () {
      return this.tabs.filter((tab) => {
        const authorized = this.$store.getters["auth/getScope"](tab.scope);

        return (
          this.routeFromEpsAllowed(tab.route) &&
          this.routeForThisLeadTypeAllowed(tab.route) &&
          authorized
        );
      });
    },
    tabsLength: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
        default:
          return 4;
      }
    },
    primaryTabs: function () {
      return this.allowedTabs.slice(0, this.tabsLength);
    },
    otherTabs: function () {
      return this.allowedTabs.slice(this.tabsLength);
    },
    is_lead_from_eps() {
      if (!this.lead?.id) {
        return null;
      }

      return this.lead.is_from_eps;
    },
    risk_location_city() {
      return this.lead.risk_location?.city || {};
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.changePageTitleByRouteChanging(to);
        this.reloadLeadByRouteChanging(to, from);
      },
    },
    is_lead_from_eps: {
      handler(value) {
        if (value === false) {
          if (!this.routeFromEpsAllowed(this.$route.name)) {
            this.$router.push({
              name: "lead_detail_data",
              params: { lead_number: this.lead_number },
            });
          }
        }
      },
      immediate: true,
    },
    lead_type: {
      handler() {
        if (!this.routeForThisLeadTypeAllowed(this.$route.name)) {
          this.$router.push({
            name: "lead_detail_data",
            params: { lead_number: this.lead_number },
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.load_page(this.lead_number);
  },
  destroyed() {
    // Desconecta do canal da lead ao desconectar
    Echo.leaveChannel(
      `private-leads.${this.$store.getters.getLead.identifier_code}`
    );
  },
};
</script>

<style lang="scss" scoped>
.lead_detail {
  .toolbar_lead {
    // margin-top: -12px;
    // margin-left: -12px;
    // margin-right: -12px;
  }
}

.notifications-icons {
  position: absolute;
  top: 30px;
  right: 10px;
}
</style>
