<template>
  <v-data-iterator
    :items="items"
    :options.sync="options"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15, 50],
    }"
    :loading="loading"
    item-key="id"
    @update:options="$emit('update:options', $event)"
    :server-items-length="totalItems"
  >
    <template v-slot:default="{ items }">
      <List
        :items="items"
        :dense="dense"
        :all-read="allRead"
        :checkable="checkable"
      />
    </template>

    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ __("nenhuma notificação encontrada") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:loading>
      <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
    </template>
  </v-data-iterator>
</template>

<script>
import List from "@/components/Lead/Notification/List";
import i18n from "@/mixins/i18n";

export default {
  components: { List },
  mixins: [i18n],
  props: {
    items: Array,
    loading: Boolean,
    totalItems: Number,
    dense: Boolean,
    allRead: Boolean,
    checkable: Boolean,
  },

  data() {
    return {
      i18n_prefix: "notifications",
      options: {
        itemsPerPage: 10,
        sortBy: "group_created_at",
        sortDesc: true,
        mustSort: true,
      },
    };
  },
};
</script>
