<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <EpsImportLeadForm @cancel="close()" @submitted="handleSubmit" />
  </v-dialog>
</template>

<script>
import EpsImportLeadForm from "./EpsImportLeadForm.vue";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import i18n from "@/mixins/i18n";

export default {
  components: {
    EpsImportLeadForm,
  },

  mixins: [ToastsMixin, i18n],

  data() {
    return {
      i18n_prefix: "eps_import",
      dialog: false,
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    handleSubmit() {
      this.toast(this.__("Importação concluída", "eps_import"));
      this.close();
      this.$emit("submit");
    },
  },
};
</script>
