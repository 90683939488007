<template>
  <v-alert dense type="warning" v-if="lead.related.length">
    {{ getDescription() }}
    <template v-slot:append>
      <v-dialog width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="warning darken-2" small>
            {{ __("ver") }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title> {{ __("leads relacionadas") }} </v-card-title>

          <v-list>
            <v-list-item
              v-for="related_lead in lead.related"
              :key="related_lead.related_lead_id"
              link
              :href="`/leads/${related_lead.related_lead_number}`"
              class="primary--text"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ related_lead.related_lead_number }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ related_lead.motive_description }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon> mdi-open-in-new </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </template>
  </v-alert>
</template>

<script>
import i18n from "@/mixins/i18n";
export default {
  props: {
    lead: Object,
  },

  mixins: [i18n],

  data() {
    return {
      i18n_prefix: "leads",
    };
  },

  methods: {
    getDescription() {
      const count = this.getRelatedCount();
      return count === 1
        ? `${count} ${this.__("lead relacionada", "leads")}`
        : `${count} ${this.__("leads relacionadas", "leads")}`;
    },

    getRelatedCount() {
      return this.lead.related.length;
    },
  },
};
</script>
