<template>
  <v-card>
    <v-tabs v-model="tab" show-arrows>
      <v-tab>
        {{ __("todas") }}
      </v-tab>
      <v-tab>
        {{ __("companhia") }}
      </v-tab>
      <v-tab>
        {{ __("unidade") }}
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-card-actions class="py-0">
      <v-card-subtitle class="d-flex align-center">
        {{ __("ordenar por:") }}
        <v-menu top close-on-click max-height="500" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              text
              color="transparent"
              class="px-2"
            >
              {{ getSortedByText(options.sortBy) }}
              <v-icon right small>mdi-menu-down</v-icon>
            </v-chip>
          </template>
          <v-list>
            <v-list-item
              v-for="item in sortableItems"
              :key="item.value"
              @click="options.sortBy = item.value"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn-toggle v-model="options.sortDesc" mandatory dense>
          <v-btn icon :value="false" x-small>
            <v-icon :value="false">mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn icon :value="true" x-small>
            <v-icon :value="false">mdi-chevron-down</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-card-subtitle>
      <v-spacer />
      <v-btn icon :loading="fetching_notifications" @click="getNotifications">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider></v-divider>
    <LeadNotificationGroup
      :items="notifications"
      :options.sync="options"
      :totalItems="totalItems"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { index as indexNotifications } from "@/services/lead_notifications";
import LeadNotificationGroup from "@/components/Lead/Notification/LeadNotificationGroup";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  components: {
    LeadNotificationGroup,
  },
  data() {
    return {
      i18n_prefix: "notifications",
      advanced_filter: {
        status: "unread",
        type_notifications: "all",
      },
      sortableItems: [
        {
          text: this.__("data de criação", "notifications"),
          value: "group_created_at",
        },
      ],
      options: {
        itemsPerPage: 10,
        sortBy: "group_created_at",
        sortDesc: true,
      },
      notifications: [],
      notifications_loaded: false,
      totalItems: 0,
      fetching_notifications: false,
      tab: null,
      type: "all",
    };
  },
  methods: {
    ...mapActions("lead_notifications", ["loadLeadNotificationsAnalytics"]),
    getSortedByText(value) {
      return this.sortableItems.find((item) => item.value === value)?.text;
    },

    getUnreadGroup(groups) {
      return groups.find((group) => group.name)?.items || [];
    },

    getReadGroup(groups) {
      return groups.find((group) => !group.name)?.items || [];
    },

    afterNotificationUpdate() {
      this.loadLeadNotificationsAnalytics();
      this.getNotifications();
    },

    async getNotifications() {
      if (!this.notifications_loaded) {
        if (this.hasFilter()) {
          // Reseta página quando carrega a lead pela primeira vez com algum filtro
          this.options.page = 1;
        }
        this.notifications_loaded = true;
      }

      this.fetching_notifications = true;

      this.advanced_filter.type_notifications = this.type;

      try {
        const response = await indexNotifications(this.options, {
          ...this.advanced_filter,
          ...this.parsedQueryFilters(),
        });
        this.notifications = response.data;
        this.totalItems = response.meta.total;
        this.advanced_filter = {
          ...this.advanced_filter,
          ...response.meta.advanced_filter,
        };
      } catch (e) {
        this.advanced_filter = {
          status: "all",
        };
      } finally {
        this.fetching_notifications = false;
      }
    },

    filterNotifications() {
      this.options.page = 1;
      this.getNotifications();
    },

    hasFilter() {
      return this.$route.query.status;
    },

    handleAdvancedFilterChange() {
      localStorage.setItem(
        "leads_notification_advanced_filter",
        JSON.stringify(this.advanced_filter)
      );

      this.filterNotifications();
    },

    getAdvancedFiltersFromStorage() {
      if (!this.hasFilter()) {
        const json_storage_filters = localStorage.getItem(
          "leads_notification_advanced_filter"
        );
        if (json_storage_filters) {
          const parsed_storage_filters = JSON.parse(json_storage_filters);
        }
      }
    },
    parsedQueryFilters() {
      return {
        status: "unread",
      };
    },

    saveDataTableOptions(options) {
      localStorage.setItem(
        "leads_notification_datatable_options",
        JSON.stringify(options)
      );
    },
    loadDataTableOptions() {
      const json_storage_options = localStorage.getItem(
        "leads_notification_datatable_options"
      );
      if (json_storage_options) {
        const parsed_storage_options = JSON.parse(json_storage_options);
        this.options = parsed_storage_options;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getNotifications();
      },
      deep: true,
      immediate: true,
    },
    tab: function (value) {
      if (value === 0) {
        this.type = "all";
      }

      if (value === 1) {
        this.type = "company";
      }

      if (value === 2) {
        this.type = "unity";
      }

      this.getNotifications();
    },
  },
  created() {
    this.getAdvancedFiltersFromStorage();
    this.loadDataTableOptions();
  },
};
</script>

<style></style>
