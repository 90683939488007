<template>
  <v-row dense>
    <v-col cols="12" md="5">
      <v-row dense>
        <v-col>
          <v-card outlined>
            <v-card-title>{{ __("detalhes") }}</v-card-title>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b>
                      {{ lead.insurer.name }}
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("sinistro em") }}
                    <b>
                      {{ sinister_in }}
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("sinistro ocorrido em") }}
                    <b>{{ formatDate(lead.claim_date) }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon> </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("participação em") }}
                    <b>{{ formatDate(lead.claim_involvement_date) }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon> </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("data de recebimento") }}
                    <b>{{ formatDate(lead.created_at) }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon> </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ __("data de envio do serviço") }}
                    <b>{{ formatDate(lead.creation_date) }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span v-if="lead.claim_description">{{
                    lead.claim_description
                  }}</span>
                  <v-list-item-subtitle v-else
                    >{{ __("reclamação do segurado", "participants") }} :
                    {{ __("a definir", "participants") }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list subheader two-line v-if="lead.is_from_eps">
              <v-divider inset></v-divider>
              <v-subheader inset>{{ __("integração eps") }}</v-subheader>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary"> mdi-cog-transfer-outline </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    {{ __("última ação externa") }}:
                    <b>{{ lead.default_service.action }}</b>
                  </v-list-item-title>
                  <v-list-item-title
                    class="text-wrap py-2"
                    v-if="lead.default_service.motive"
                  >
                    {{ __("motivo da ação externa") }}:
                    <b>{{ lead.default_service.motive }}</b>
                  </v-list-item-title>
                  <v-list-item-title
                    class="text-wrap"
                    v-if="lead.default_service.motive_justification"
                  >
                    {{ __("justificativa") }}:
                    <b>{{ lead.default_service.motive_justification }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-text>
              <v-btn block elevation="0" @click="lead_detail_modal = true">{{
                __("ver todos")
              }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card outlined>
            <v-card-title>
              {{ __("apólice") }}
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                :to="{ name: 'lead_detail_policy' }"
                >{{ __("ver mais") }}</v-btn
              >
            </v-card-title>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-clock-start</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ __("início em") }}
                    <b>{{
                      formatDate(lead.policy.begin_date)
                    }}</b></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-clock-end</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="lead.policy.end_date"
                    >{{ __("encerra em")
                    }}<b>
                      {{ formatDate(lead.policy.end_date) || "--" }}</b
                    ></v-list-item-title
                  >
                  <v-list-item-title v-else>
                    {{ __("Indeterminada") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card outlined>
            <v-card-title>{{ __("participantes") }}</v-card-title>
            <v-list>
              <v-subheader>
                {{ __("lesados") }} ({{ injureds.length }})
              </v-subheader>
              <v-list-item
                v-for="participant in injureds"
                :key="participant.id"
              >
                <v-list-item-avatar>
                  <v-avatar
                    :color="
                      participant.type.canonical == 'insured'
                        ? 'primary'
                        : '#ccc'
                    "
                  >
                    <v-icon dark>mdi-account</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ participant.contact.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ participant.type.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-subheader>
                {{ __("não lesados", "participants") }} ({{
                  non_injureds.length
                }})
              </v-subheader>
              <v-list-item
                v-for="participant in non_injureds"
                :key="participant.id"
              >
                <v-list-item-avatar>
                  <v-avatar
                    :color="
                      participant.type.canonical == 'insured'
                        ? 'primary'
                        : '#ccc'
                    "
                  >
                    <v-icon dark>mdi-account</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ participant.contact.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ participant.type.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-text>
              <v-btn block elevation="0" @click="openParticipantDialog">{{
                __("editar participantes")
              }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="7">
      <v-row dense>
        <v-col>
          <resume-unity-card />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <resume-management-card />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card outlined class="mb-4">
            <v-list two-line>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-clipboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b>{{ __("tipo de peritagem") }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="lead.is_traditional_appraisal">
                    <spam>{{ __("tradicional") }}</spam>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                    <spam>{{ __("não tradicional") }}</spam>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <LeadResume />
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="participant_dialog" max-width="600px">
      <participants-detail @close="participant_dialog = false" />
    </v-dialog>

    <v-dialog v-model="lead_detail_modal" max-width="600px">
      <v-card>
        <v-card-title>
          {{ __("sinistro") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="lead_detail_modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-simple-table class="mx-2">
          <tbody>
            <tr>
              <td class="font-weight-medium">
                {{ __("gestor") }}
              </td>
              <td>{{ lead.manager }}</td>
            </tr>
            <tr v-if="lead.is_from_eps">
              <td class="font-weight-medium">
                {{ __("status externo") }}
              </td>
              <td>
                <v-chip class="ma-1">{{
                  lead.default_service.external_status
                    ? lead.default_service.external_status.state.name
                    : ""
                }}</v-chip>
              </td>
            </tr>
            <tr v-if="lead.is_from_eps">
              <td class="font-weight-medium">
                {{ __("última ação externa") }}
              </td>
              <td>
                {{ lead.default_service.action }}
              </td>
            </tr>
            <tr v-if="lead.is_from_eps">
              <td class="font-weight-medium">
                {{ __("motivo da ação externa") }}
              </td>
              <td>
                {{ lead.default_service.motive }}
              </td>
            </tr>
            <tr v-if="lead.is_from_eps">
              <td class="font-weight-medium">
                {{ __("justificativa da ação externa") }}
              </td>
              <td>
                {{ lead.default_service.motive_justification }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("data do sinistro") }}
              </td>
              <td class="text-left">{{ formatDate(lead.claim_date) }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("data de participação") }}
              </td>
              <td class="text-left">
                {{ formatDate(lead.claim_involvement_date) }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("tomador") }}
              </td>
              <td class="text-left">
                <v-chip v-if="lead.participant_insured">{{
                  abreviate_participant_name(lead.participant_insured)
                }}</v-chip>
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("descrição") }}
              </td>
              <td class="text-left">{{ lead.claim_description }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("tipo de regularização") }}
              </td>
              <td class="text-left">{{ lead.claim_regularizatioun_type }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("tipo de ocorrência") }}
              </td>
              <td class="text-left">{{ lead.claim_occurrence_type }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-card-title>{{ __("leads") }}</v-card-title>
        <v-simple-table class="mx-2">
          <tbody>
            <tr>
              <td class="font-weight-medium">
                {{ __("data de recebimento") }}
              </td>
              <td class="text-left">{{ formatDate(lead.created_at) }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("data de envio do serviço") }}
              </td>
              <td class="text-left">{{ formatDate(lead.creation_date) }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("referência externa") }}
              </td>
              <td class="text-left">{{ lead.external_reference }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("tipo de produto") }}
              </td>
              <td class="text-left">{{ lead.product_template_type }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("clientes") }}
              </td>
              <td class="text-left">{{ lead.insurer.name }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("serviços") }}
              </td>
              <td>
                <v-chip v-for="service in lead.services" :key="service.id">{{
                  service.service_number
                }}</v-chip>
              </td>
            </tr>
          </tbody> </v-simple-table
        ><v-card-title>
          {{ __("local de risco enviado pela companhia") }}
        </v-card-title>
        <v-simple-table class="mx-2">
          <tbody>
            <tr>
              <td class="font-weight-medium">
                {{ __("distrito") }}
              </td>
              <td>{{ risk_location_country_area }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("concelho") }}
              </td>
              <td>{{ risk_location_city }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("freguesia") }}
              </td>
              <td>{{ risk_location_city_area }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("morada") }}
              </td>
              <td class="text-wrap" v-text="risk_location_street"></td>
            </tr>
            <tr>
              <td class="font-weight-medium">
                {{ __("código postal") }}
              </td>
              <td>{{ risk_location_postal_code }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatDate } from "@/tools/date";
import NameTool from "@/tools/names";

import LeadResume from "@/components/Lead/LeadResume";
import ParticipantsDetail from "@/components/Lead/ParticipantsDetail";
import ResumeUnityCard from "@/components/Lead/ResumeUnityCard";
import ResumeManagementCard from "@/components/Lead/ResumeManagementCard";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  components: {
    LeadResume,
    ParticipantsDetail,
    ResumeUnityCard,
    ResumeManagementCard,
  },
  data: () => ({
    i18n_prefix: "leads",
    participant_dialog: false,
    lead_detail_modal: false,
  }),
  methods: {
    handleChangeTeam(team) {
      this.$store.commit("setLead", {
        ...this.lead,
        team,
        team_id: team.id,
      });
    },
    handleChangeManager(manager) {
      this.$store.commit("setLead", {
        ...this.lead,
        service_manager: manager,
        service_manager_id: manager.id,
      });
    },
    handleChangeUnity(unity) {
      this.$store.commit("setLead", {
        ...this.lead,
        unity,
        unity_id: unity.id,
      });
    },
    formatDate(date) {
      return formatDate(date);
    },
    openParticipantDialog() {
      this.participant_dialog = true;
    },
    abreviate_participant_name(participant) {
      if (participant && participant.contact)
        return NameTool.abreviate(participant.contact.name);
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },
    injureds: function () {
      if (!this.lead) return [];

      return this.lead.participants.filter(
        (participant) => participant.insured == true
      );
    },
    non_injureds: function () {
      if (!this.lead) return [];

      return this.lead.participants.filter(
        (participant) => participant.insured == false
      );
    },
    sinister_in() {
      if (this.lead?.current_risk_location) {
        return `${this.current_risk_location_city}, ${this.current_risk_location_country_area}`;
      }

      return "N/A";
    },
    current_risk_location_city() {
      return this.lead?.current_risk_location?.city?.name || "S/F";
    },
    current_risk_location_country_area() {
      return this.lead?.current_risk_location?.country_area?.name || "S/F";
    },
    risk_location_street() {
      return `${this.lead.risk_location?.street || "S/F"}, ${
        this.lead.risk_location?.number || "S/F"
      }`;
    },
    risk_location_country_area() {
      return this.lead.risk_location?.country_area?.name || "S/F";
    },
    risk_location_city() {
      return this.lead.risk_location?.city?.name || "S/F";
    },
    risk_location_city_area() {
      return this.lead.risk_location?.city_area?.name || "S/F";
    },
    risk_location_postal_code() {
      return this.lead.risk_location?.postal_code || "S/F";
    },
  },
};
</script>
