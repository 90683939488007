<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" icon>
        <v-icon>mdi-view-column</v-icon>
      </v-btn>
    </template>

    <v-card height="300" style="overflow-y: auto">
      <v-list>
        <v-subheader>
          {{ __("colunas") }}
          <v-btn
            @click="handleRestore()"
            icon
            small
            class="ml-2"
            :title="__('restaurar padrões')"
          >
            <v-icon small>mdi-restore</v-icon>
          </v-btn>
        </v-subheader>
        <v-list-item @click="handleSelectAll()">
          <v-list-item-action>
            <v-simple-checkbox
              @click="handleSelectAll()"
              :value="allSelected"
              readonly
              :ripple="false"
            ></v-simple-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> {{ __("selecionar tudo") }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          v-for="header in visibleHeaders"
          :key="header.value"
          @click="handleSwitch(header)"
        >
          <v-list-item-action>
            <v-simple-checkbox
              @click="handleSwitch(header)"
              :value="isActive(header)"
              :ripple="false"
              readonly
            ></v-simple-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ header.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import i18n from "@/mixins/i18n";

export default {
  props: {
    modelValue: Array,
    headers: Array,
    storageKey: String,
    defaultHeaders: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [i18n],

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  data() {
    return {
      i18n_prefix: "buttons",
    };
  },

  methods: {
    update(newValue) {
      const parsedHeaders = this.headers.filter((element) => {
        if (element.fixed) {
          return true;
        }
        return newValue.find((header) => header.value === element.value);
      });

      this.store(parsedHeaders);

      this.$emit("update:modelValue", parsedHeaders);
    },

    handleRestore() {
      this.update([...this.defaultHeaders]);
    },

    handleSelectAll() {
      if (this.allSelected) {
        this.update([]);
        return;
      }

      this.update([...this.headers]);
    },

    store(headers) {
      localStorage.setItem(this.storageKey, JSON.stringify(headers));
    },

    getFromStorage() {
      try {
        return localStorage.getItem(this.storageKey)
          ? JSON.parse(localStorage.getItem(this.storageKey))
          : this.defaultHeaders;
      } catch (error) {
        console.error("Falha ao ler headers da localStorage", error);
        return this.defaultHeaders;
      }
    },

    isActive(header) {
      for (let item of this.modelValue) {
        if (item.value === header.value) {
          return true;
        }
      }

      return false;
    },

    handleSwitch(header) {
      if (this.isActive(header)) {
        this.removeHeader(header);
        return;
      }

      this.addHeader(header);
    },

    removeHeader(header) {
      this.update(
        this.modelValue.filter((element) => element.value !== header.value)
      );
    },

    addHeader(header) {
      this.update(this.modelValue.concat(header));
    },
  },

  computed: {
    visibleHeaders() {
      return this.headers.filter((header) => !!!header.fixed);
    },

    allSelected() {
      return this.headers.length === this.modelValue.length;
    },
  },

  created() {
    this.update(this.getFromStorage());
  },
};
</script>
