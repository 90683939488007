var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',{staticClass:"pa-4",attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":_vm.__('pesquisar'),"solo":"","dense":"","single-line":"","hide-details":"","clearable":""},on:{"click:clear":function($event){return _vm.handleClearSearch()},"change":function($event){return _vm.onSearchChange()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.loadItems()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('SelectedColumnsButton',{attrs:{"headers":_vm.headers,"defaultHeaders":[
            {
              text: this.__('lead'),
              value: 'identifier_code',
              fixed: true,
            },
            {
              text: this.__('tipo'),
              value: 'lead_type_description',
            },
            { text: this.__('subtipo'), value: 'subtype_id' },
            { text: this.__('status'), value: 'current_status_name' },
            { text: this.__('gestor'), value: 'service_manager_name' },
            { text: this.__('companhia'), value: 'insurer_name' },
          ],"storage-key":"leads-columns"},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/leads/new","link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.__("adicionar lead")))])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleOpenEpsImporter()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cloud-download")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.__("importar da eps"))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-sheet',{staticClass:"py-2 px-4",attrs:{"elevation":"0"}},[_c('AdvancedFilterChips',{attrs:{"filters":_vm.advancedFilters,"storedFilters":_vm.storedAdvancedFilters},on:{"click:filter":function($event){return _vm.openFiltersDialog()},"click:item":_vm.handleClickUpdateFilter,"click:clear":function($event){return _vm.clearAdvancedFilters()},"applyFilter":_vm.applyFilters,"removeFilter":_vm.removeStoredAdvancedFilter}})],1),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[(_vm.options)?_c('v-data-table',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.items,"height":_vm.height - 305,"fixed-header":"","options":_vm.options,"server-items-length":_vm.totalItems,"disable-pagination":"","must-sort":"","loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 15],
        'show-current-page': true,
        'show-first-last-page': true,
      }},on:{"update:options":function($event){return _vm.onOptionsUpdate($event)}},scopedSlots:_vm._u([{key:"item.identifier_code",fn:function({ item }){return [_c('router-link',{attrs:{"to":`leads/${item.identifier_code}`}},[_vm._v(_vm._s(item.identifier_code))])]}},{key:"item.risk_location",fn:function({ item }){return [_vm._v(" "+_vm._s(item.country_area_name)+", "+_vm._s(item.city_name)+" ")]}},{key:"item.subtype_id",fn:function({ item }){return [_vm._v(" "+_vm._s(item.subtype_description)+" ")]}},{key:"item.reopening_at",fn:function({ item }){return [_vm._v(" "+_vm._s(item.reopening_at_diff)+" ")]}}],null,false,101407154)}):_vm._e()],1),_c('AdvancedFiltersTable',{ref:"advancedFilters",attrs:{"template":_vm.advancedFiltersTemplate,"table-key":"leads","storedFilters":_vm.storedAdvancedFilters},on:{"apply":_vm.applyFilters,"filter_stored":_vm.handleFilterStored,"removeFilter":_vm.removeStoredAdvancedFilter}}),_c('EpsImportLeadDialog',{ref:"eps_import_dialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }